import React, { Component } from "react";
import { FaGithub, FaYoutube, FaSpotify, FaSoundcloud } from "react-icons/fa";
import { GiReactor } from "react-icons/gi";
import { BsGearWideConnected } from "react-icons/bs";

const ServiceList = [
    {
        icon: <FaYoutube />,
        title: 'YouTube',
        description: 'Watch a variety of synthesizer demos and original music productions.',
	link: 'https://www.youtube.com/channel/UClOCLZKXy8r2-T63XuuWhdw'
    },
    {
        icon: <FaSoundcloud />,
        title: 'Soundcloud',
        description: 'Listen to my audio sketches, ideas, demos.',
	link: 'https://soundcloud.com/bradleygray'
    },
    {
        icon: <FaSpotify />,
        title: 'EOF',
        description: 'Find my electronic music released on Spotify.',
	link: 'https://open.spotify.com/artist/3kS5U9tQsnJYUkz0IVAD7y?si=l-6FTRd_RPWnAsOLqNXq4Q'
    },
    {
        icon: <BsGearWideConnected />,
        title: 'Hands of Industry',
        description: 'A 3-piece industrial synthpop band with live drums.',
	link: 'http://www.handsofindustry.co.uk/'
    },
    {
        icon: <FaGithub />,
        title: 'Github',
        description: 'my personal software projects, opensource tinkering. ',
	link: 'https://github.com/x-trade'
    },
    {
        icon: <GiReactor />,
        title: 'Reaktor User Library',
        description: 'My creations for Native Instruments\' Reaktor',
	link: 'https://www.native-instruments.com/en/reaktor-community/reaktor-user-library/all/all/all/1280730/'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Links',
        description = 'Here you will find links to my various outlets across the web.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/contact"><span className="text">Contact</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href={val.link}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
