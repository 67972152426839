import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";

/**
 * Shuffles array in place.
 * @param {Array} a items An array containing the items.
 */
function shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}

const PortfolioList = shuffle([
    {
        image: 'image-1',
        category: 'Video',
        title: ' Two Prophet12s',
	link: "https://www.youtube.com/watch?v=bh27TrAtgmo&t=5s"
    },
    {
        image: 'image-2',
        category: 'Audio',
        title: ' Televisual - DFAM and Drumbrute Tryout',
	link: "https://soundcloud.com/bradleygray/televisual"
    },
    {
        image: 'image-3',
        category: 'Video',
        title: ' Novation Peak - Analog Exploration',
	link: "https://www.youtube.com/watch?v=X3UVOWjWhWo"
    },
    {
        image: 'image-4',
        category: 'Video',
        title: ' Modular Jam III',
	link: "https://www.youtube.com/watch?v=Vmg2xoIb83o"
    },
    {
        image: 'image-5',
        category: 'Audio',
        title: ' Inky Blackness - Random Ambient',
	link: "https://soundcloud.com/bradleygray/inky-blackness"
    },
    {
        image: 'image-6',
        category: 'Video',
        title: ' DSI Prophet12 - Sound Design Session I',
	link: "https://www.youtube.com/watch?v=rPWK2rJbSBs"
    },
    {
        image: 'image-7',
        category: 'Video',
        title: ' Moog Sub 37 - Late Night Jam',
	link: "https://www.youtube.com/watch?v=vigt7Y6rS7I"
    },
    {
        image: 'image-8',
        category: 'Audio',
        title: ' Peaked - High Energy 80s Dance WIP',
	link: "https://soundcloud.com/bradleygray/peaked"
    }
])



class Portfolio extends Component{
    render(){
        let title = 'Multimedia',
        description = 'Browse a selection of my latest works and releases.';
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>{title}</h2>
                                    <p>{description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {PortfolioList.map((value , index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{value.category}</p>
                                            <h4><a href={value.link}>{value.title}</a></h4>
                                            <div className="portfolio-button">
                                                <a className="rn-btn" href={value.link}>Open</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;
