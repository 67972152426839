import React, { Component } from "react";

const possibleAboutImages = [
    "/assets/images/about/about-1.jpg",
    "/assets/images/about/about-2.jpg",
    "/assets/images/about/about-3.jpg",
    "/assets/images/about/about-4.jpg",
    "/assets/images/about/about-5.jpg",
    "/assets/images/about/about-6.jpg",
    "/assets/images/about/about-7.jpg"
]

let chosenAboutImage = possibleAboutImages[Math.floor(Math.random() * possibleAboutImages.length)];

class AboutTwo extends Component{
    render(){
        let title = 'About Me',
        description = 'I\'m a Linux systems and DevOps engineer, software developer, as well as performer, composer, and producer of music in Leeds (UK), and an Audio Music Technology (BSc) graduate from the University of the West of England. \n' +
            'I like to balance engineering and creativity in both my professional and hobbyist capacities. Aside from music and computers I am also interested in writing and photography. \n' +
            'I currently play synthesizers and keyboards for the industrial synthpop band Hands of Industry, as well as releasing experimental music under my own name and synthwave under the artist name EOF. ';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src={chosenAboutImage} alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutTwo;
