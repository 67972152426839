import React, { Component , Fragment } from "react";
import { Parallax } from "react-parallax";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import ServiceTwo from "../elements/service/ServiceTwo";
import AboutTwo from "../component/HomeLayout/homeOne/AboutTwo";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import ModalVideo from 'react-modal-video';
import Helmet from "../component/common/Helmet";

const possibleHeaderBackgrounds = [
    "/assets/images/bg/paralax/banner-1.jpg",
    "/assets/images/bg/paralax/banner-2.jpg",
    "/assets/images/bg/paralax/banner-4.jpg"
]

let headerBackground = possibleHeaderBackgrounds[Math.floor(Math.random() * possibleHeaderBackgrounds.length)];

const SlideList = [
    {
        textPosition: 'text-left',
        category: '',
        title: 'Bradley Gray',
        description: '',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    }
]

class StudioAgency extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <Fragment>
                <Helmet pageTitle="Bradley Gray" />
                {/* Start Header Area  */}
                <Header />
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-activation slider-creative-agency">
                    <Parallax bgImage={headerBackground} strength={400}>
                        {SlideList.map((value , index) => (
                            <div className="slide slide-style-2 slider-video-bg d-flex align-items-center justify-content-center" key={index} data-black-overlay="6">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-8">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                {value.description ? <p className="description">{value.description}</p> : ''}
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="video-inner">
                                                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='bh27TrAtgmo' playlist='PUlOCLZKXy8r2-T63XuuWhdw' onClose={() => this.setState({isOpen: false})} />
                                                <button className="video-popup theme-color" onClick={this.openModal}><span className="play-icon"></span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                ))}
                    </Parallax>

                    {/* End Single Slide */}

                    
                </div>
                {/* End Slider Area   */}


                {/* Start About Area */}
                <div id="about" className="about-area ptb--120">
                    <AboutTwo />
                </div>
                {/* End About Area */}

                {/* Start Portfolio Area */}
                <div id="media" className="portfolio-area ptb--80 bg_image bg_image--3">
                    <div className="portfolio-sacousel-inner mb--55 mb_sm--30">
                        <Portfolio />
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start Service Area  */}
                <div id="links" className="service-area ptb--120 bg_color--1">
                    <div className="container">
                        <ServiceTwo />
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start Footer Area  */}
                <FooterTwo />
                {/* End Footer Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default StudioAgency;
